export class TableState {
    sort?: {id: string, start: string};
    filter?: string;
    pageIndex?: number;
    pageSize?: number;

    updateState(sortActive: string, sortDirection: string, filter?: string, pageIndex?: number, pageSize?: number): void {
        if (!this.sort) {
            this.sort = {
                id: sortActive,
                start: sortDirection
            };
        } else {
            this.sort.id = sortActive;
            this.sort.start = sortDirection;
        }
        this.filter = filter;
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
    }
}
