// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    firebase: {
        apiKey: 'AIzaSyCj18b1fISkpQQ8erGfQ2ocHr7_VO_ixDk',
        authDomain: 'toni-chatbot-backend-dev.firebaseapp.com',
        databaseURL: 'https://toni-chatbot-backend-dev.firebaseio.com',
        projectId: 'toni-chatbot-backend-dev',
        storageBucket: 'toni-chatbot-backend-dev.appspot.com',
        messagingSenderId: '1033334629603'
    },

    // Fallback server URL (if not set in customer's product config):
    serverURL: 'https://cdd2fe3b.ngrok.io'
    // serverURL: 'https://effibot.inmotion.dhl/srv'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
