export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'BROADCASTS': 'Broadcasts',
            'SAMPLE'        : {
                'TITLE': 'Sample',
                'BADGE': '25'
            },
            'ECOMMERCE'   : 'E-Commerce',
            'RAFFLES': 'Raffles',
            'SURVEYS': 'Surveys',
            'ENGAGEMENT': 'Fan Engagement',
            'MATCHES': 'Matches',
            'ANALYTICS': 'Analytics',
            'SYSTEM_ADMIN': 'System Administration',
            'CHAT'   : 'Chat',
        }
    }
};
