import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { environment } from 'environments/environment';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
import { AuthService } from 'app/auth/auth.service';
import { Statistics } from './statistics.model';
// tslint:disable-next-line:no-duplicate-imports
// import {default as _rollupMoment} from 'moment';

// const moment = _rollupMoment || _moment;
const moment = _moment;


@Injectable()
export class AnalyticsService implements Resolve<any>
{
    routeParams: any;
    statistics: Statistics;
    onStatisticsChanged: BehaviorSubject<Statistics>;

    constructor(
        private http: HttpClient,
        private authService: AuthService
    )
    {
        // Set the defaults
        this.onStatisticsChanged = new BehaviorSubject(null);
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {

        this.routeParams = route.params;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getStatistics()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getStatistics(fromDate: Date = new Date(), toDate: Date = new Date()): Promise<Statistics> {
        return new Promise((resolve, reject) => {
            const productConfigId = this.authService.productConfigIdOfCurrentUser;
            this.http.get(this.authService.serverURL + `/internal-statistics/overview?token=inot17&productConfig=${productConfigId}&format=json`)
                .subscribe((response: Statistics) => {
                    this.statistics = response;
                    this.onStatisticsChanged.next(this.statistics);
                    resolve(response);
                }, reject);
        });
    }

}
