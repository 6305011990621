import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'app/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { ShortLink } from './short-link.model';

@Injectable()
export class ShortLinkGeneratorService
{
    constructor(
        private http: HttpClient,
        private authService: AuthService,
    )
    {
    }


    postCreateShortLink(url: string, broadcastId: string): Promise<any> {
        const productConfigId = this.authService.productConfigIdOfCurrentUser;
        const productConfig = this.authService.productConfigCurrentUser;
        const json = {
            url: url,
            prefix: productConfig.shortLinks.prefix, // 'http://localhost:8080'
            broadcastBackendId: broadcastId
        };
        return this.http.post(this.authService.serverURL + `/api/v1/short-link/create?pc=${productConfigId}`, json).toPromise();
    }

    getShortLinksForBroadcast(broadcastId: string): Observable<Array<ShortLink>> {
        const productConfigId = this.authService.productConfigIdOfCurrentUser;
        return this.http.get<Array<ShortLink>>(this.authService.serverURL + `/api/v1/short-link/broadcast/${broadcastId}?pc=${productConfigId}`);
    }

}
