
import { MatChipInputEvent } from '@angular/material';

import { FuseUtils } from '@fuse/utils';
import { QueryDocumentSnapshot } from '@angular/fire/firestore';
import { environment } from 'environments/environment';

export enum UserRole {
    admin
}

export class ProductConfig {
    id: number;
    serverURL?: string;
    shortLinks?: {
        prefix: string; // For example: http://localhost:8080
    };
}

export class LicenseFeature {
    id: string;
    validUntil?: Date;
}

export class License {
    features: Array<LicenseFeature>;
}

export class Customer {
    id: string;
    name: string;
    users: Map<UserRole, Array<string>>;
    config: ProductConfig;
    license: License;

    constructor(customer?)
    {
        console.log('Customer constructor', customer);
        customer = customer || {};
        this.id = customer.id; // || FuseUtils.generateGUID();
        this.name = customer.name || '';
        this.config = customer.config || {};
        this.users = customer.users || [];
        this.license = customer.license || { features: [] };
    }

    /**
     * This is called when we load a Survey from Firebase.
     * @param id 
     * @param data 
     */
    static parseFirebaseData(id: string, data: any): any {
        console.log('parseFirebaseData begin', id, data);

        if (data.license && data.license.features) {
            for (const feature of data.license.features) {
                if (feature.validUntil) {
                    feature.validUntil = feature.validUntil.toDate(); // Convert Firebase timestamp to JS Date
                }
            }
        }

        console.log('parseFirebaseData end', data);
        return data;
    }
}
