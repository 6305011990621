import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Quiz } from '../quiz/quiz.model';
import { AuthService } from '../../../../auth/auth.service';
import { TableState } from 'app/utils/table-state';

@Injectable()
export class QuizzesService implements Resolve<any>
{
    quizzes: any[];
    onQuizzesChanged: BehaviorSubject<any>;

    // The state of the table
    tableState = new TableState();

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private http: HttpClient,
        private db: AngularFirestore,
        private authService: AuthService,
    )
    {
        // Set the defaults
        this.onQuizzesChanged = new BehaviorSubject({});
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getQuizzes()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get all quizzes
     *
     * @returns {Promise<any>}
     */
    getQuizzes(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            const productConfigId = this.authService.productConfigIdOfCurrentUser;
            console.log('getQuizzes() productConfigId', productConfigId);
            this.db.collection<any>('quizzes', ref => ref.where('productConfigId', '==', productConfigId).orderBy('createdAt', 'desc')).snapshotChanges().pipe(
                map(actions => actions.map(a => {
                    const doc = a.payload.doc;
                    console.log('mappping quiz with id', doc.id);
                    return Quiz.parseFirebaseData(doc.id, doc.data());
                }))
            ).subscribe((response: any) => {
                console.log('got quizzes:', response);
                this.quizzes = response;
                this.onQuizzesChanged.next(this.quizzes);
                resolve(response);
            }, reject);
        });
    }
}
