import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Raffle } from '../raffle/raffle.model';
import { AuthService } from '../../../../auth/auth.service';
import { TableState } from 'app/utils/table-state';

@Injectable()
export class RafflesService implements Resolve<any>
{
    raffles: any[];
    onRafflesChanged: BehaviorSubject<any>;

    // The state of the table
    tableState = new TableState();

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private http: HttpClient,
        private db: AngularFirestore,
        private authService: AuthService,
    )
    {
        // Set the defaults
        this.onRafflesChanged = new BehaviorSubject({});
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getRaffles()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get raffles
     *
     * @returns {Promise<any>}
     */
    getRaffles(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            const productConfigId = this.authService.productConfigIdOfCurrentUser;
            console.log('getRaffles() productConfigId', productConfigId);
            this.db.collection<any>('raffles', ref => ref.where('productConfigId', '==', productConfigId).orderBy('startDate', 'desc')).snapshotChanges().pipe(
                map(actions => actions.map(a => {
                    const doc = a.payload.doc;
                    console.log('mappping raffle with id', doc.id);
                    return Raffle.parseFirebaseData(doc.id, doc.data());
                }))
            ).subscribe((response: any) => {
                console.log('got raffles:', response);
                this.raffles = response;
                this.onRafflesChanged.next(this.raffles);
                resolve(response);
            }, reject);
        });
    }
}
