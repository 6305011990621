import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ShortLinkGeneratorService } from './short-link-generator.service';
import { Observable } from 'rxjs';
import { ShortLink } from './short-link.model';

@Component({
    selector   : 'short-link-generator',
    templateUrl: './short-link-generator.component.html',
    styleUrls  : ['./short-link-generator.component.scss']
})
export class ShortLinkGeneratorComponent
{

    @Input() pageType: string;
    @Input() broadcastId: string;
    @Input() disabled: boolean;
    shortLinkUrls: Array<{shortLinkUrl: string, originalUrl: string}> = [];

    /**
     * Constructor
     */
    constructor(
        private shortLinkGeneratorService: ShortLinkGeneratorService,
        private snackBar: MatSnackBar,
    )
    {
        console.log('ShortLinkGeneratorComponent constructor');
    }

    createShortLink(url: string): void {
        this.shortLinkGeneratorService.postCreateShortLink(url, this.broadcastId).then(response => {
            // console.log('postCreateShortLink response', response);
            this.shortLinkUrls.unshift(response);
        });
    }

    copiedToClipboard($event: {isSuccess: true, content: string}): void {
        // Show the success message
        this.snackBar.open(`Copied short link "${$event.content}" to clipboard.`, 'OK', {
            verticalPosition: 'top',
            duration        : 2000
        });
    }
}
