import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { environment } from 'environments/environment';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
import { AuthService } from 'app/auth/auth.service';
import { EngagementLeadersResponse } from './engagement.model';
// tslint:disable-next-line:no-duplicate-imports
// import {default as _rollupMoment} from 'moment';

// const moment = _rollupMoment || _moment;
const moment = _moment;


@Injectable()
export class EngagementService implements Resolve<any>
{
    routeParams: any;
    engagementLeaders: EngagementLeadersResponse;
    onEngagementLeadersChanged: BehaviorSubject<EngagementLeadersResponse>;

    constructor(
        private http: HttpClient,
        private authService: AuthService
    )
    {
        // Set the defaults
        this.onEngagementLeadersChanged = new BehaviorSubject(null);
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {

        this.routeParams = route.params;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getPointLeaders()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getPointLeaders(fromDate: Date = null, toDate: Date = null): Promise<EngagementLeadersResponse> {
        if (fromDate === null) {
            const aMonthAgo = _moment().subtract(1, 'month');
            aMonthAgo.set('hour', 0);
            aMonthAgo.set('minute', 0);
            aMonthAgo.set('second', 0);
            aMonthAgo.set('millisecond', 0);
            fromDate = aMonthAgo.toDate();
        }

        if (toDate === null) {
            const endOfToday = _moment();
            endOfToday.set('hour', 23);
            endOfToday.set('minute', 59);
            endOfToday.set('second', 59);
            endOfToday.set('millisecond', 999);
            toDate = endOfToday.toDate();
        }
        return new Promise((resolve, reject) => {
            const productConfigId = this.authService.productConfigIdOfCurrentUser;
            this.http.get(this.authService.serverURL + `/api/v1/engagement/leaders?pc=${productConfigId}&fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`)
                .subscribe((response: EngagementLeadersResponse) => {
                    this.engagementLeaders = response;
                    this.onEngagementLeadersChanged.next(this.engagementLeaders);
                    resolve(response);
                }, reject);
        });
    }

}
