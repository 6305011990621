import { FuseUtils } from '@fuse/utils';
import { QueryDocumentSnapshot } from '@angular/fire/firestore';
import { Message } from '../../broadcasts/broadcast/broadcast.model';

export class QuizQuestion {
    text: string;
    imageUrl?: string;
    options: Array<string>;
    correctAnswerIndex: number;
    correctText?: string;
    correctImageUrl?: string;
    wrongText?: string;
    wrongImageUrl?: string;
    moreInfoUrl?: string;
    moreInfoButtonText?: string;
}

export class QuizOutcome {
    text: string;
    imageUrl?: string;
    points?: number;
}

export class Quiz
{
    id: string;
    name: string;
    productConfigId: number;
    enabled: boolean;
    // startDate: Date;
    // endDate?: Date;
    subtitle?: string;
    introduction?: string;
    startImageUrl?: string;
    numberOfQuestions: number;
    outcome: Array<QuizOutcome>;
    questions: Array<QuizQuestion>;
    handle: string;
    createdByUserId: string;
    // language: string; // 'de', 'en', 'all'
    createdAt: Date;
    updatedAt: Date;

    constructor(survey?)
    {
        console.log('Survey constructor', survey);
        survey = survey || {};
        this.id = survey.id; // || FuseUtils.generateGUID();
        this.name = survey.name || '';
        this.handle = survey.handle || FuseUtils.handleize(this.name);
        this.productConfigId = survey.productConfigId;
        // this.startDate = survey.startDate;
        // this.endDate = survey.endDate;
        this.enabled = survey.enabled;
        this.subtitle = survey.subtitle;
        this.introduction = survey.introduction;
        this.outcome = survey.outcome;
        this.questions = survey.questions;
        this.createdAt = survey.createdAt;
        this.updatedAt = survey.updatedAt;
        this.createdByUserId = survey.createdByUserId;
        console.log('Quiz constructor, handle', this.handle, this);
    }

    /**
     * This is called when we load a Quiz from Firebase.
     * @param id 
     * @param data 
     */
    static parseFirebaseData(id: string, data: any): any {
        console.log('parseFirebaseData begin', id, data);
        if (!data.id) {
            data.id = id;
        }
        // if (data.startDate) {
        //   data.startDate = new Date(data.startDate.seconds * 1000);
        // }
        // if (data.endDate) {
        //   data.endDate = new Date(data.endDate.seconds * 1000);
        // }
        if (data.createdAt) {
            data.createdAt = new Date(data.createdAt.seconds * 1000);
        }
        if (data.updatedAt) {
            data.updatedAt = new Date(data.updatedAt.seconds * 1000);
        }

        data.handle = FuseUtils.handleize(data.name);
        console.log('parseFirebaseData end', data);
        return data;
    }
}
