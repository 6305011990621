import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { auth, User } from 'firebase/app';
import { Observable, of } from 'rxjs';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { Constants } from 'app/shared/constants';
import { Customer, ProductConfig } from './customer.model';
import { CustomerService } from './customer.service';
import { take, flatMap, map } from 'rxjs/operators';
import { environment } from 'environments/environment';

export interface UserCustomerInfo {
    user: User;
    customer: Customer;
}

/*
const USER_PRODUCT_CONFIG_MAPPING = {
    // LAOLA1:
    'klemens+laola1@toni.ai': Constants.PRODUCT_CONFIG_ID_LAOLA1,
    'christoph+laola1@toni.ai': Constants.PRODUCT_CONFIG_ID_LAOLA1,
    // Toni:
    'klemens@toni.ai': Constants.PRODUCT_CONFIG_ID_TONI,
    'christoph@toni.ai': Constants.PRODUCT_CONFIG_ID_TONI,
    // DJ Teddy-O:
    'klemens+teddyo@toni.ai': Constants.PRODUCT_CONFIG_ID_TEDDYO,
    'christoph+teddyo@toni.ai': Constants.PRODUCT_CONFIG_ID_TEDDYO,
    'booking@djteddy-o.com': Constants.PRODUCT_CONFIG_ID_TEDDYO,
    // Emmi CAFFE LATTE:
    'klemens+emmi@toni.ai': Constants.PRODUCT_CONFIG_ID_EMMI,
    'christoph+emmi@toni.ai': Constants.PRODUCT_CONFIG_ID_EMMI,
    'daniel.hupfer@brandsetter.at': Constants.PRODUCT_CONFIG_ID_EMMI,
    'emmi@brandsetter.at': Constants.PRODUCT_CONFIG_ID_EMMI,
    // spusu LIGA:
    'klemens+spusuliga@toni.ai': Constants.PRODUCT_CONFIG_ID_SPUSU_LIGA,
    'christoph+spusuliga@toni.ai': Constants.PRODUCT_CONFIG_ID_SPUSU_LIGA,
    'office@spusuliga.at': Constants.PRODUCT_CONFIG_ID_SPUSU_LIGA,
    'socialmedia@spusuliga.at': Constants.PRODUCT_CONFIG_ID_SPUSU_LIGA,
    'presse@spusuliga.at': Constants.PRODUCT_CONFIG_ID_SPUSU_LIGA,
    'tom.berger@spusuliga.at': Constants.PRODUCT_CONFIG_ID_SPUSU_LIGA
};

const PRODUCT_CONFIGS = [
    {
        id: 0,
        name: 'DEFAULT',
    },
    {
        id: 1,
        name: 'LAOLA1 Toni',
    },
    {
        id: 2,
        name: 'Toni ⚽️ Chatbot',
    },
    {
        id: 3,
        name: 'DJ TEDDY-O',
    },
    {
        id: 4,
        name: 'Emmi CAFFÉ LATTE',
    },
    {
        id: 5,
        name: 'FC Red Bull Salzburg',
    },
    {
        id: 6,
        name: 'spusu LIGA',
    }
];
*/

/**
 * We use Firebase Auth for login.
 * See http://javasampleapproach.com/frontend/angular/angular-4-firebase-auth-email-password-authentication-with-angularfire2-email-login
 * and https://angularfirebase.com/lessons/angular-firebase-authentication-tutorial-email-password-signup/
 */
@Injectable()
export class AuthService {

  authState: User = null;
  customer: Customer = null;

  constructor(
        private afAuth: AngularFireAuth,
        private router: Router,
        private _fuseNavigationService: FuseNavigationService,
        private customerService: CustomerService) {
    console.log('AuthService constructor');
    this.afAuth.authState.subscribe((user) => {
        console.log('afAuth.authState.subscribe email', user ? user['email'] : '');
      this.authState = user;
      if (user && !this.customer) {
        this.loadCustomerForUser(user).subscribe(({customer}) => {
            this.updateAfterAuthStateChange(user, customer);
        });
      } else {
          this.updateAfterAuthStateChange(null, null);
      }
    });
  }

  loadCustomerForUser(user: User): Observable<UserCustomerInfo> {
    if (!user) {
        return of({user, customer: null});
    }
    const role = 'admin';
    if (this.customer && this.customer.users[role] && this.customer.users[role].includes(user.uid)) {
        // console.log('loadCustomerForUser: customer is already loaded:', this.customer.id);
        return of({user, customer: this.customer});
    }
    return this.customerService.getCustomersForUserId(role, user.uid).pipe(
        take(1),
    map(value => {
        // console.log('loadCustomerForUser: getCustomersForUserId got', value);
        const customer = value[0];
        return {user, customer};
    }));
  }

  updateAfterAuthStateChange(user?: User, customer?: Customer): void {
    this.customer = customer;
    this.updateNavigation(user);
  } 

    updateNavigation(user: User): void {
        this._fuseNavigationService.setCurrentNavigation(user ? 'main' : 'main-loggedOut');
        const showSystemAdmin = user && user['email'] && user['email'].endsWith('@toni.ai');
        
        // this._fuseNavigationService.updateNavigationItem('system-admin', {
        //     hidden: !showSystemAdmin
        // });
        // this._fuseNavigationService.updateNavigationItem('chat', {
        //     hidden: !showSystemAdmin
        // });

        // this._fuseNavigationService.updateNavigationItem('sport-events', {
        //     hidden: ![
        //         0,
        //         Constants.PRODUCT_CONFIG_ID_LAOLA1,
        //         Constants.PRODUCT_CONFIG_ID_TONI,
        //         Constants.PRODUCT_CONFIG_ID_TEDDYO
        //     ].includes(this.productConfigIdOfCurrentUser)
        // });

        let enabledMenuItems = [];
        if (this.customer && this.customer.license && this.customer.license.features) {
            const now = new Date();
            enabledMenuItems = this.customer.license.features
                .filter(
                    // Check if feature in license is still valid
                    feature => !feature.validUntil || feature.validUntil.getTime() > now.getTime()
                )
                .map(
                    feature => feature.id
                );
        } else if (!this.customer) {
            console.log('Customer object not yet available');
        } else if (!this.customer.license) {
            console.log('Customer: license not available');
        } else if (!this.customer.license.features) {
            console.log('Customer: features not available in license');
        }
        
        // const navigation = this._fuseNavigationService.getCurrentNavigation();
        const applications = this._fuseNavigationService.getNavigationItem('applications');

        if (applications && applications.children) {
            for (const item of applications.children) {
                const hidden = !enabledMenuItems.includes(item.id);
                // console.log(`item ${item.id} is hidden ${hidden}`);
                if (item.id === 'system-admin') {
                    this._fuseNavigationService.updateNavigationItem(item.id, {
                        hidden: !showSystemAdmin
                    });
                } else {
                    this._fuseNavigationService.updateNavigationItem(item.id, {
                        hidden: hidden
                    });
                    // if (item.children) {
                    //     // console.log('Item has children', item.children);
                    //     for (const child of item.children) {
                    //         this._fuseNavigationService.updateNavigationItem(child.id, {
                    //             hidden: hidden
                    //         });
                    //     }
                    // }
                }
            }
        } else {
            console.log('applications nav item not available', applications);
        }
    }

  getAuthStateObservable(): Observable<UserCustomerInfo> {
    return this.afAuth.authState.pipe(flatMap(user => this.loadCustomerForUser(user)));
  }

  get isUserAnonymousLoggedIn(): boolean {
    return (this.authState !== null) ? this.authState.isAnonymous : false;
  }

  get currentUserId(): string {
    return (this.authState !== null) ? this.authState.uid : '';
  }

  get currentUserName(): string {
    return (this.authState !== null) ? this.authState['email'] : '';
  }

  get currentUser(): any {
    return (this.authState !== null) ? this.authState : null;
  }

  get isUserEmailLoggedIn(): boolean {
    if ((this.authState !== null) && (!this.isUserAnonymousLoggedIn)) {
      return true;
    } else {
      return false;
    }
  }

  signUpWithEmail(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        this.authState = userCredential.user;
        return userCredential;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }

  loginWithEmail(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        this.authState = userCredential.user;
        return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
            this.loadCustomerForUser(userCredential.user).subscribe(() => {
                resolve(userCredential);
            });
        });
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }

  signOut(): void {
    this.afAuth.auth.signOut();
    this.router.navigate(['/']);
  }

  get productConfigIdOfCurrentUser(): number {
    //   return this.currentUserName ? USER_PRODUCT_CONFIG_MAPPING[this.currentUserName] : null;
    return this.customer ? this.customer.config.id : null;
  }

  get productConfigCurrentUser(): ProductConfig {
    return this.customer ? this.customer.config : null;
  }

  /**
   * The server URL (chatbot/API server) for the currently logged in user 
   */
  get serverURL(): string {
    return this.customer && this.customer.config && this.customer.config.serverURL ? this.customer.config.serverURL : environment.serverURL;
  }

  get productConfigNameOfCurrentUser(): string {
    // const productConfig = PRODUCT_CONFIGS.filter(pc => pc.id === this.productConfigIdOfCurrentUser);
    // if (productConfig.length > 0) {
    //     return productConfig[0].name;
    // } else {
    //     return 'BACKEND';
    // }
    return this.customer ? this.customer.name : 'BACKEND';
  }

  /**
   * Do you want to persist the user's login state locally or just for the session?
   * @param doRemember true if the user state shall be persistet
   */
  setPersistUser(doRemember: boolean): Promise<any> {
    return this.afAuth.auth.setPersistence(doRemember ? auth.Auth.Persistence.LOCAL : auth.Auth.Persistence.SESSION);
  }
}
