import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'broadcasts',
                title    : 'Broadcasts',
                translate: 'NAV.BROADCASTS',
                type     : 'item',
                icon     : 'message',
                url      : '/apps/broadcasts/broadcasts',
                // 'exactMatch': true,
                // badge    : {
                //     title    : '25',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
            },
            {
                id       : 'raffles',
                title    : 'Raffles',
                translate: 'NAV.RAFFLES',
                type     : 'collapsable',
                icon     : 'stars',
                children : [
                    {
                        id        : 'raffles',
                        title     : 'Manage',
                        type      : 'item',
                        url      : '/apps/raffles/raffles',
                        exactMatch: true
                    },
                    {
                        id        : 'raffles-stats',
                        title     : 'Statistics',
                        type      : 'item',
                        url      : '/apps/raffles/stats',
                        exactMatch: true
                    }
                ]
            },
            {
                id       : 'surveys',
                title    : 'Surveys',
                translate: 'NAV.SURVEYS',
                type     : 'collapsable',
                icon     : 'ballot',
                children : [
                    {
                        id        : 'surveys',
                        title     : 'Manage',
                        type      : 'item',
                        url      : '/apps/surveys/surveys',
                        exactMatch: true
                    },
                    {
                        id        : 'surveys-stats',
                        title     : 'Statistics',
                        type      : 'item',
                        url      : '/apps/surveys/stats',
                        exactMatch: true
                    }
                ]
            },
            {
                id       : 'engagement',
                title    : 'Engagement',
                translate: 'NAV.ENGAGEMENT',
                type     : 'item',
                icon     : 'insert_emoticon',
                url      : '/apps/engagement',
            },
            {
                id       : 'chat',
                title    : 'Chat',
                translate: 'NAV.CHAT',
                type     : 'item',
                icon     : 'chat',
                url      : '/apps/chat',
                /*badge    : {
                    title: '10',
                    bg   : '#09d261',
                    fg   : '#FFFFFF'
                }*/
            },
            {
                id       : 'sport-events',
                title    : 'Matches',
                translate: 'NAV.MATCHES',
                type     : 'item',
                icon     : 'event',
                url      : '/apps/sport-events',
            },
            {
                id       : 'analytics',
                title    : 'Analytics',
                translate: 'NAV.ANALYTICS',
                type     : 'item',
                icon     : 'insert_chart_outlined', // bar_chart, insert_chart, insert_chart_outlined, show_chart
                url      : '/apps/analytics',
            },
            {
                id       : 'system-admin',
                title    : 'System Administration',
                translate: 'NAV.SYSTEM_ADMIN',
                type     : 'item',
                icon     : 'work',
                url      : '/apps/system-admin',
                hidden   : true,
            }
        ]
    }
];
