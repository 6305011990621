import { MatChipInputEvent } from '@angular/material';

import { FuseUtils } from '@fuse/utils';
import { QueryDocumentSnapshot } from '@angular/fire/firestore';
import { Message } from '../../broadcasts/broadcast/broadcast.model';

export class Raffle
{
    id: string;
    name: string;
    productConfigId: number;
    enabled: boolean;
    startDate: Date;
    endDate?: Date;
    sendBroadcastAtStart?: boolean;
    introText?: String;
    pricesText?: String;
    conditionsText?: String;
    submissionText: String;
    submissionMessage: Message;
    handle: string;
    createdByUserId: String;
    language: String; // 'de', 'en', 'all'
    createdAt: Date;
    updatedAt: Date;
    engagementPoints: number;

    constructor(raffle?)
    {
        console.log('Raffle constructor', raffle);
        raffle = raffle || {};
        this.id = raffle.id; // || FuseUtils.generateGUID();
        this.name = raffle.name || '';
        this.handle = raffle.handle || FuseUtils.handleize(this.name);
        this.productConfigId = raffle.productConfigId;
        this.startDate = raffle.startDate;
        this.endDate = raffle.endDate;
        this.enabled = raffle.enabled;
        this.sendBroadcastAtStart = raffle.sendBroadcastAtStart;
        this.introText = raffle.introText;
        this.pricesText = raffle.pricesText;
        this.conditionsText = raffle.conditionsText;
        this.submissionText = raffle.submissionText;
        this.submissionMessage = raffle.submissionMessage;
        this.createdAt = raffle.createdAt;
        this.updatedAt = raffle.updatedAt;
        this.createdByUserId = raffle.createdByUserId;
        this.engagementPoints = raffle.engagementPoints;
        console.log('Raffle constructor, handle', this.handle, this);
    }

    /**
     * This is called when we load a Raffle from Firebase.
     * @param id 
     * @param data 
     */
    static parseFirebaseData(id: string, data: any): any {
        console.log('parseFirebaseData begin', id, data);
        if (!data.id) {
            data.id = id;
        }
        if (data.startDate) {
          data.startDate = new Date(data.startDate.seconds * 1000);
        }
        if (data.endDate) {
          data.endDate = new Date(data.endDate.seconds * 1000);
        }
        if (data.createdAt) {
            data.createdAt = new Date(data.createdAt.seconds * 1000);
        }
        if (data.updatedAt) {
            data.updatedAt = new Date(data.updatedAt.seconds * 1000);
        }

        data.handle = FuseUtils.handleize(data.name);
        console.log('parseFirebaseData end', data);
        return data;
    }
}
