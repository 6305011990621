export const locale = {
    lang: 'de',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applikationen',
            'BROADCASTS': 'Broadcasts',
            'SAMPLE'        : {
                'TITLE': 'Beispiel',
                'BADGE': '25'
            },
            'ECOMMERCE'   : 'E-Commerce',
            'RAFFLES': 'Gewinnspiele',
            'SURVEYS': 'Umfragen',
            'ENGAGEMENT': 'Fan Engagement',
            'MATCHES': 'Spiele',
            'ANALYTICS': 'Analytics',
            'SYSTEM_ADMIN': 'System-Administration',
            'CHAT'   : 'Chat',
        }
    }
};
