import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore, DocumentChangeAction, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Customer } from './customer.model';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/storage';
import { UploadMetadata } from '@angular/fire/storage/interfaces';
import { FuseUtils } from '@fuse/utils';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class CustomerService implements Resolve<any>
{
    routeParams: any;
    customer: any;
    onCustomerChanged: BehaviorSubject<any>;
    collection: AngularFirestoreCollection;

    constructor(
        private http: HttpClient,
        private afDb: AngularFirestore,
        private afStorage: AngularFireStorage,
        private snackBar: MatSnackBar
    )
    {
        this.collection = this.afDb.collection<any>('customers');
        // Set the defaults
        this.onCustomerChanged = new BehaviorSubject({});
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {

        this.routeParams = route.params;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getCustomer()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getCustomer(): Promise<any>
    {
        console.log('getCustomer', this.routeParams.id);

        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onCustomerChanged.next(false);
                resolve(false);
            }
            else
            {
                // this.http.get('api/e-commerce-products/' + this.routeParams.id)
                //     .subscribe((response: any) => {
                //         this.product = response;
                //         this.onProductChanged.next(this.product);
                //         resolve(response);
                //     }, reject);
                this.collection.doc(this.routeParams.id).snapshotChanges().pipe(
                    map(change => Customer.parseFirebaseData(change.payload.id, change.payload.data()))
                ).subscribe((response: any) => {
                    console.log('got customer:', response);
                    this.customer = response;
                    this.onCustomerChanged.next(this.customer);
                    resolve(response);
                }, reject);
            }
        });
    }

    getCustomerById(): Observable<Customer> {
        return this.collection.doc(this.routeParams.id).snapshotChanges().pipe(
            map(change => Customer.parseFirebaseData(change.payload.id, change.payload.data()))
        );
    }

    getCustomersForUserId(role: string, userId: string): Observable<Customer[]> {
        return this.afDb.collection<any>('customers', ref => ref.where('users.' + role, 'array-contains', userId)).snapshotChanges().pipe(
            map(actions => actions.map(a => {
                const doc = a.payload.doc;
                console.log('mappping customer with id', doc.id);
                const customerData = Customer.parseFirebaseData(doc.id, doc.data());
                return new Customer(customerData);
            }))
        );
    }

    saveCustomer(customer): Promise<void>
    {
        return new Promise((resolve, reject) => {
            const document = this.collection.doc(customer.id);
            return document.update(Object.assign({}, customer));
        });
    }

    addCustomer(customer): Promise<void|Error>
    {
        const id = this.afDb.createId();
        customer.id = id;
        const document = this.collection.doc(id);
        try {
            return document.set(Object.assign({}, customer));
        } catch (err) {
            return Promise.reject(err);
        }
    }

}
