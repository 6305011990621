import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule, MatInputModule, MatSelectModule, MatSortModule, MatSnackBarModule, MatCheckboxModule, MatCardModule } from '@angular/material';
import { ClipboardModule } from 'ngx-clipboard';

import { FuseSharedModule } from '@fuse/shared.module';

import { ShortLinkGeneratorComponent } from './short-link-generator.component';
import { ShortLinkGeneratorService } from './short-link-generator.service';

@NgModule({
    declarations: [
        ShortLinkGeneratorComponent
    ],
    imports     : [
        RouterModule,

        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatCardModule,

        FuseSharedModule,

        ClipboardModule
    ],
    exports     : [
        ShortLinkGeneratorComponent
    ],
    providers   : [
        ShortLinkGeneratorService
    ]
})
export class ShortLinkGeneratorModule
{
}
