import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators'; 
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // if (localStorage.getItem('currentUser')) {

        return this.authService.getAuthStateObservable().pipe(map(user => {
            if (this.authService.isUserEmailLoggedIn) {
                // logged in so return true
                console.log('AuthGuard.canActivate: true');
                return true;
            }
            console.log('AuthGuard.canActivate: false');

            // not logged in so redirect to login page with the return url
            this.router.navigate(['pages', 'auth', 'login'], { queryParams: { returnUrl: state.url }});
            return false;
        }));

        // return true;
    }
}
